import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <SEO title="Dracma Decision Aids" />
  </Layout>
)

export default IndexPage
